// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-coach-js": () => import("./../src/pages/coach.js" /* webpackChunkName: "component---src-pages-coach-js" */),
  "component---src-pages-dancer-choreographer-js": () => import("./../src/pages/dancer/choreographer.js" /* webpackChunkName: "component---src-pages-dancer-choreographer-js" */),
  "component---src-pages-dancer-classes-js": () => import("./../src/pages/dancer/classes.js" /* webpackChunkName: "component---src-pages-dancer-classes-js" */),
  "component---src-pages-dancer-events-js": () => import("./../src/pages/dancer/events.js" /* webpackChunkName: "component---src-pages-dancer-events-js" */),
  "component---src-pages-dancer-freestyle-js": () => import("./../src/pages/dancer/freestyle.js" /* webpackChunkName: "component---src-pages-dancer-freestyle-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-js": () => import("./../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-event-js": () => import("./../src/templates/event.js" /* webpackChunkName: "component---src-templates-event-js" */)
}

